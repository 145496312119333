"use client";

import { useEffect } from "react";
import { useRouter, usePathname } from "next/navigation";

export default function Layout({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const router = useRouter();
  const pathname = usePathname();
  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1492px)");
    const handleMediaChange = (): undefined => {
      if (!mediaQuery.matches) {
        router.replace("/m");
      } else {
        router.replace(pathname.includes("/m") ? "/" : pathname);
      }
    };

    handleMediaChange();
    mediaQuery.addEventListener("change", handleMediaChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, [router, pathname]);
  return <>{children}</>;
}
